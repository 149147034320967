import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import users from "./reg_users.module";
import member_groups from "./member_groups.module";
import citizens from "./citizens.module";
import reservations from "./reservations.module";
import cities from "./cities.module";
import states from "./states.module";
import regions from "./regions.module";
import countries from "./countries.module";
import events from "./events.module";
import doses from "./doses.module";
import availabilities from "./availabilities.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    users,
    member_groups,
    reservations,
    citizens,
    cities,
    states,
    regions,
    countries,
    events,
    doses,
    availabilities
  },
});
