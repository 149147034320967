// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const UPDATE_BASE_INFO = "updateUserBaseInfo";
export const UPDATE_MEMBER_GROUP = "updateUserMemberGroup";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_BASE_INFO = "setUserBaseInfo";
export const SET_MEMBER_GROUP = "setUserMemberGroup";

const state = {
  user_base_info: {},
  user_member_group: {
    // is_admin: false,
    // is_reseller: false,
  },
  user_personal_info: {
    photo: "media/users/300_21.jpg",
    name: "James",
    surname: "Jones",
    company_name: "Fifestudios",
    job: "Application Developer",
    email: "matt@fifestudios.com",
    phone: "44(76)34254578",
    company_site: "fifestudios",
  },
  user_account_info: {
    username: "nick84",
    email: "nick.watson@loop.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false,
    },
    verification: true,
  },
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  },

  currentUserBaseInfo(state) {
    return state.user_base_info;
  },

  currentUserMemberGroup(state) {
    return state.user_member_group;
  },

  isCurrentUserAdmin(state) {
    return state.user_member_group.is_admin;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [UPDATE_BASE_INFO](context, payload) {
    context.commit(SET_BASE_INFO, payload);
  },
  [UPDATE_MEMBER_GROUP](context, payload) {
    context.commit(SET_MEMBER_GROUP, payload);
  },
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },
  [SET_BASE_INFO](state, user_base_info) {
    state.user_base_info = user_base_info.data;
  },
  [SET_MEMBER_GROUP](state, user_member_group) {
    state.user_member_group = user_member_group;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
