import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {
  UPDATE_BASE_INFO,
  UPDATE_MEMBER_GROUP,
} from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const USERINFO = "userInfo";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const REFRESH_TOKEN = "refreshToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user_data: {},
  auth: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user_data;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  authError(state) {
    return state.errors;
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, auth) {
    state.isAuthenticated = true;
    state.auth = auth;
    // state.user = auth;
    state.errors = {};
    JwtService.saveToken(state.auth.token);
    JwtService.saveRefreshToken(state.auth.refresh_token);
  },
  [SET_USER](state, user) {
    state.user_data = user;
    state.errors = {};
  },
  [SET_PASSWORD](state, password) {
    state.user_data.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.auth = {};
    state.user_data = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
  },
};

const actions = {
  [LOGIN](context, credentials) {
    // context.commit(PURGE_AUTH);
    context.commit(SET_ERROR, {});
    return new Promise((resolve) => {
      ApiService.setLoginHeader();
      ApiService.post("login_check", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    // context.commit(PURGE_AUTH);
    context.commit(SET_ERROR, {});
    return new Promise((resolve) => {
      ApiService.setLoginHeader();
      if (JwtService.getRefreshToken() != null) {
        ApiService.post("token/refresh", {
          refresh_token: JwtService.getRefreshToken(),
        })
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            JwtService.destroyRefreshToken();
            context.commit(SET_ERROR, response);
            location.reload()
          });
      } else {
        context.commit(PURGE_AUTH);
        window.location.href = "/login";
      }
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          if (data.code == 200 && data.message == "active") {
            if (context.getters.currentUserBaseInfo.id == undefined) {
              context.dispatch(USERINFO);
            }
            // return data
          }
        })
        .catch(function (error) {
          // Something happened in setting up the request that triggered an Error
          if (error.message != undefined && error.message.includes("401")) {
            if (JwtService.getRefreshToken() != null) {
              context.dispatch(REFRESH_TOKEN);
              // location.reload()
            } else {
              context.commit(PURGE_AUTH);
            }
          }
          context.commit(SET_ERROR, error.data);
        });
    } else {
      // context.commit(PURGE_AUTH);
    }
  },
  [USERINFO](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/reg_users/1/current").then(({ data }) => {
        var user_tmp = [];
        var excluded = ["member_group"];
        Object.keys(data).forEach((key) => {
          if (!excluded.includes(key)) {
            user_tmp[key] = data[key];
          }
        });
        context.dispatch(UPDATE_BASE_INFO, user_tmp);
        context.dispatch(UPDATE_MEMBER_GROUP, data.data.member_group);
      });
    }
  },

  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
