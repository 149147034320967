// ITA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  LOGIN: {
    WELLCOME: "Vacine Reservations",
    MESSAGE: "Vacine Reservations ADMIN",
    ERROR: {
      USERNAME: "Nome utente richiesto",
      PASSWORD: "Password richiesta",
      ACCESS: "Credenziali errate!",
      PROVIDED: "Verificare di aver inserito user e password!"
    }
  },
  FORM: {
    USERNAME: "Nome utente",
    PASSWORD: "Password",
    FORGOT_PASSWORD: "Password dimenticata?",
    LOGIN: "Accedi",
    CREATE_ACCOUNT_TEXT: "Non hai un utenza?",
    CREATE_ACCOUNT_LINK: "Registrati",
    CANCEL: "Annulla",
    SAVE: "Salva"
  },
  ASIDE: {
    TABS: {
      TITLEROOT: "Cruscotto",
      DASHBOARD_HOME: "Bacheca",
      TOGGLE: "Apri/Chiudi submenu",
      ROOT_USERS: "Utenti",
      ROOT_COMPANIES: "Aziende",
      ROOT_RESERVATIONS: "Prenotazioni",
      ROOT_DOSES: "Dosi",
      ROOT_SERVER: "Server",
      GENERAL_SUPPORT: "Help",
      GENERAL_SUPPORT_TICKET_ROOT: "Tickets",
      GENERAL_SUPPORT_NEW_TICKET: "Nuovo",
      GENERAL_SUPPORT_TICKET: "Lista",
      GENERAL_SUPPORT_FAQ_ROOT: "FAQ",
      USERS_ADMIN_DASHBOARD: "Riepilogo",
      USERS_ADMIN_LIST: "Lista",
      USERS_ADMIN_ADD: "Aggiungi",
      USERS_ADMIN_MEMBERGROUP: "Gruppi",
      USERS_ADMIN_MEMBERGROUP_ADD: "Nuovo gruppo",
      USERS_ADMIN_ACTIVITY: "Attività",
      USERS_ADMIN_BLACKLIST: "Blacklist",
      COMPANIES_ADMIN_DASHBOARD: "Riepilogo",
      COMPANIES_ADMIN_LIST: "Lista",
      COMPANIES_ADMIN_ADD: "Aggiungi",
      CONSTRUCTION_SITE_ADMIN_DASHBOARD: "Riepilogo",
      CONSTRUCTION_SITE_ADMIN_LIST: "Lista",
      CONSTRUCTION_SITE_ADMIN_ADD: "Aggiungi",
      SERVER_ADMIN_DASHBOARD: "Dashbord",
      SERVER_ADMIN_LIST: "Lista",
      SERVER_ADMIN_ADD: "Aggiungi",
      SECURITY: "Shield",
      SECURITY_DASHBOARD: "Dashboard",
      SECURITY_BLOCKED_IP: "IP Bloccati",
      SECURITY_BLOCKED_ISP: "ISP Bloccati",
      SECURITY_BLOCKED_USER_AGENT: "User Agents Bloccati",
      SECURITY_PROCESS_LIST: "Process list",
      CITIZENS_ADMIN_LIST: "Lista",
      ROOT_CITIZENS: "Anagrafiche",
      ROOT_EVENTS: "Eventi",
      CITIZENS_ADMIN_ADD: "Aggiungi",
      RESERVATIONS_ADMIN_DASHBOARD: "Riepilogo",
      RESERVATIONS_ADMIN_LIST: "Lista",
      RESERVATIONS_ADMIN_ADD: "Aggiungi prenotazione",
      DOSES_ADMIN_LIST: "Lista",
      DOSES_ADMIN_ADD: "Aggiungi",
      EVENTS_ADMIN_LIST: "Lista",
      EVENTS_ADMIN_ADD: "Aggiungi"
    }
  },
  COMMON: {
    USER_PROFILE: "Profilo utente",
    LOG_OUT: "Disconnetti",
    SAVING_SUCCESS: "Salvataggio completato",
    FILTER: "Filtra...",
    SELECT_ALL_FEMALE: "Seleziona tutte",
    SELECT_ALL_MALE: "Seleziona tutti",
    STATUS: {
      ACTIVE: "Attivo",
      NOT_ACTIVE: "Sospeso"
    },
    BUTTON: {
      SAVE: "Salva",
      CONFIRM_RESERVATION: "Conferma prenotazione",
      SAVE_USER: "Salva utente",
      SAVED_USER: "Utente salvato",
      CANCEL: "Annulla",
      OK: "Ok",
      NEXT_STEP: "Avanti",
      PREV_STEP: "Indietro"
    },
    ERRORS: {
      REQUIRED: "Richiesto",
      REQUEST: {
        GENERIC: "Errore durante il salvataggio"
      }
    },
    WIZARD: {
      ALERT: {
        COMPLETE_ALL_FIELDS: "Compila tutti i campi necessari",
        EVENT_RESERVATION_DUPLICATED:
          "L'utente ha già una prenotazione per questo evento."
      }
    },
    SUCCESS: {
      TITLE: "Dati memorizzati"
    },
    TABLES: {
      COLUMN: {
        ID: "ID",
        CODE: "CODICE",
        EMAIL: "Email",
        DENOMINATION: "Denominazione",
        SUBSCRIPTION_STATE: "Stato sottoscrizione",
        DESCRIPTION: "Descrizione",
        VAT: "P.IVA",
        CITY: "Città",
        STATE: "Provincia",
        USERNAME: "Username",
        GROUP: "Gruppo",
        STATUS: "Status",
        OWNERID: "Parent",
        LAST_LOGIN: "Ultimo accesso",
        OPERATION: "Operazioni",
        GROUP_NAME: "Titolo",
        IS_ADMIN: "Admin",
        IS_RESELLER: "Rivenditore",
        IS_BANNED: "Bannato",
        TITLE: "Titolo",
        IP: "IP",
        DNS: "dns",
        SERVER_STATUS: "Abilitato",
        CONNECTION_STATUS: "Stato",
        TYPOLOGY: "Tipologia",
        PLUS_OPERATION: "Operazioni",
        COMPANY: "Azienda",
        SUBSCRIPTION_DATE: "Data iscrizione",
        FISCAL_CODE: "Cod.Fiscale",
        ADDRESS: "Indirizzo",
        CONSTRUCTIONS_SITE_COUNT: "Cantieri",
        NAME: "Nome",
        SURNAME: "Cognome",
        DATE_OF_BIRTH: "Data di nascita",
        RESIDENT: "Residente",
        UPDATED_AT: "Aggiornamento",
        CREATED_AT: "Registrazione",
        RESERVATION_DATE: "Data prenotazione",
        RESERVATION_TIME: "Ora prenotazione",
        DOSE: "Dose",
        EVENT_TITLE: "Evento",
        REGISTRATION_DATE: "Data Richiesta",
        START_DATE: "Inzio evento",
        END_DATE: "Fine evento",
        TIME_SLOT: "Timeslot",
        RESERVATIONS_PER_TIME_SLOT: "Prenotazioni per slot",
        RESERVATIONS: "Confermate"
      },
      PAGINATION: "Righe per pagina"
    },
    DIALOG: {
      DELETE_CONFIRM: "Sei certo di voler cancellare questo elemento?",
      ABORT: "Annulla",
      OK: "OK",
      SAVE: "Salva",
      LOADER_TITLE: "Attendere... elaborazione dati"
    }
  },
  PAGES: {
    CITIZENS: {
      AREA: "Anagrafica",
      NEW: {
        TITLE_PERSON: "Nuova utenza"
      },
      EDIT: {
        TITLE: "Modifica utente",
        TITLE_PERSON: ""
      },
      OVERVIEW: {
        SUB_MENU: {
          OVERVIEW: "Riepilogo",
          VIEW: "Vista",
          CADASTRE: "Catasto",
          ENERGY: "Energia",
          SACE: "Sace"
        },
        TITLE: "Analisi",
        FOREIGN: "Estero",
        BORN_DATE: "Data nascita",
        DEAD_DATE: "Data morte",
        FISCAL_CODE: "Codice fiscale",
        BORN_SITE: "Luogo di nascita",
        RESIDENCE_ADDRESS: "Indirizzo residenza",
        DOMICILE_ADDRESS: "Indirizzo domicilio",
        PHONE: "Telefono",
        MOBILE_PHONE: "Telefono mobile",
        EMAIL: "Email",
        PEC: "PEC",
        FAX: "FAX"
      },
      FORM: {
        MANAGE: {
          NAME: "Nome",
          RESIDENCE_TITLE: "Dati residenza",
          DOMICILE_TITLE: "Dati domicilio",
          CONTACT_INFO: "Dati di contatto",
          BIRTH_TITLE: "Dati di nascita",
          SURNAME: "Cognome",
          FISCAL_CODE: "Codice fiscale",
          POSITION: "Posizione",
          POSITION_1: "In vita",
          POSITION_2: "Defunto",
          POSITION_3: "Non specificato",
          COUNTRIES: "Nazione",
          REGIONS: "Regione",
          STATES: "Provincia",
          CITIES: "Località",
          ADDRESS: "Indirizzo",
          ZIP: "CAP",
          COPY_DOMICILE_FROM_RESIDENCE: "Coincide con la residenza",
          DATE_OF_DEATH: "Data di morte",
          DATE_OF_BIRTH: "Data di nascita",
          RESIDENCE_IN_CITY: "Residente?",
          DATE_OF_RECIDENCE_START: "Data inizio residenza",
          DATE_OF_EMIGRATION: "Data emigrazione",
          PHONE: "Telefono",
          MOBILE_PHONE: "Telefono mobile",
          FAX: "FAX",
          EMAIL: "Email",
          PEC: "PEC",
          NOTE: "Note",
          ERROR_FISCAL_CODE_USED: "Codice fiscale già utilizzato"
        }
      },
      LIST: {
        TITLE: "Anagrafica persone fisiche",
        FILTERS: {
          ID: "Id",
          CODE: "Codice",
          NAME: "Nome",
          SURNAME: "Cognome",
          LOCATION_RESIDENCE: "Località residenza",
          LOCATION_DOMICILE: "Località domicilio",
          STATES: "Provincia",
          FISCAL_CODE: "Codice fiscale",
          RESICENDE_FROM: "Residente dal",
          RESICENDE_TO: "Residente al",
          FILTER: "Cerca"
        }
      },
      RESERVATIONS_LIST: {
        TITLE: "Lista prenotazioni"
      },
      COMMON: {
        ERROR_ONSAVE: "Errore durante il savataggio\n Verificare i dati!"
      }
    },
    RESERVATIONS: {
      AREA: "Prenotazioni",
      NEW: {
        TITLE: "Nuova",
        TITLE_PERSON: "Nuova prenotazione",
        CITIZEN_STEP: "Utente",
        TITLE_SUCCESS: "Prenotazione confermata",
        TITLE_ERROR: "Errore durante il salvataggio",
        STEPS: {
          CITIZEN: {
            TITLE: "Utente",
            SUB_TITLE: "Cerca Utente oppure creane uno nuovo",
            HELPTEXT: "Cerca",
            SEARCH: "Scrivi il testo per cercare utente",
            NAME: "Nome",
            SURNAME: "Cognome",
            SEARCH_SAVED_USER: "Utente già in archivio",
            BIRTH_DATA: "Informazioni  nascita",
            RESIDENCE_DATA: "Informazioni residenza"
          },
          EVENT: {
            TITLE: "Evento",
            SUB_TITLE: "Scegli tra gli eventi disponibili",
            SEARCH_OPEN_EVENT: "Cerca gli eventi disponibili",
            HELPTEXT: "Cerca",
            SEARCH: "Scrivi il testo per cercare eventi",
            SEARCH_TIME_OPEN_EVENT: "Seleziona un orario disponibile",
            BUSY: "Prenotati: ",
            FREE: "Liberi: ",
            DOSE: "Somministrazione dose: "
          }
        }
      },
      LIST: {
        TITLE: "Lista prenotazioni",
        FILTERS: {
          ID: "Id",
          CODE: "Codice",
          NAME: "Nome",
          SURNAME: "Cognome",
          LOCATION_RESIDENCE: "Località residenza",
          LOCATION_DOMICILE: "Località domicilio",
          STATES: "Provincia",
          FISCAL_CODE: "Codice fiscale",
          RESERVATIONS_FROM: "A partire dal",
          RESERVATIONS_TO: "Fino al",
          FILTER: "Cerca"
        }
      },
      EDIT: {
        TITLE: "Modifica prenotazione"
      },
      OVERVIEW: {
        TITLE_OVERVIEW: "Riepilogo prenotazione",
        SUB_MENU: {
          OVERVIEW: "Riepilogo",
          VIEW: "Vista"
        },
        NAME: "Nome",
        SURNAME: "Cognome",
        FISCAL_CODE: "Codice fiscale",
        DATE_OF_BIRTH: "Data di nascita",
        CITY_OF_BIRTH: "Luogo di nascita",
        PHONE: "Telefono",
        MOBILE_PHONE: "Tel. Mobile",
        EMAIL: "Email",
        RECIDENCE_ADDRESS: "Indirizzo",
        RECIDENCE_CITY: "Città residenza",
        RESUME_USER: "Riepilogo utente",
        RESUME_EVENT: "Riepilogo evento",
        RESUME_RESERVATION: "Riepilogo prenotazione",
        CODE: "Codice",
        LOCALITY: "Località",
        ADDRESS: "Indirizzo",
        TITLE: "Titolo",
        BEGIN_DATE: "Inizio",
        END_DATE: "Fine",
        RESERVATION: "Prenotazione",
        DATETIME: "Data e ora",
        CREATION_DATE: "Data creazione",
        DOSE_CODE: "Codice dose",
        DOSE: "Dose",
        DOSE_DESCRIPTION: "Descrizione dose",
        ABORT: "Annulla",
        EDIT: "Modifica",
        TITLE_DELETE_DIALOG: "Richiesta conferma",
        TEXT_DELETE_DIALOG: "Confermi di annullare questa prenotazione?",
        TITLE_EDIT_DIALOG: "Richiesta conferma",
        TEXT_EDIT_DIALOG: "Confermi di modificare questa prenotazione?"
      },
      DIALOG: {
        CODE: "Codice",
        TITLE: "Modifica prenotazione"
      },
      ATTRIBUTES: {
        CREATED_AT: "Data creazione",
        UPDATED_AT: "Data ultima modifica",
        OWNER: "Titolare prenotazione",
        EVENT: "Evento",
        NOTES: "Note",
        DATE_TIME_RESERVATION: "Data e ora"
      }
    },
    USERS: {
      AREA: "Utenti",
      COMMON: {
        NEW_USER: "Nuovo utente"
      },
      DASHBOARD: {
        TITLE: "Bacheca",
        SUBTITLE: "Utenti"
      },
      LIST: {
        TITLE: "Lista",
        TABLE: {
          TITLE: "Lista Utenti",
          FILTER: "Filtra"
        },
        FILTERS: {
          DENOMINATION: "Denominazione"
        }
      },
      NEW: {
        TITLE: "Nuovo utente",
        FORM: {}
      },
      EDIT: {
        TITLE: "Modifica utente",
        FORM: {}
      },
      FORM: {
        MANAGE: {
          ERROR_PHONE: "Formato telefono errato (solo numeri)"
        }
      },
      ATTRIBUTES: {
        USERNAME: "Username",
        NAME: "Nome",
        SURNAME: "Cognome",
        FISCALCODE: "Codice Fiscale",
        PHONE: "Telefono",
        MOBILE_PHONE: "Telefono mobile",
        EMAIL: "Email",
        NOTE: "Note",
        MEMBER_GROUP: "Membro del gruppo",
        USERNAME_REQUIRED: "Username è richiesto",
        OWNER_ID: "Referente",
        PASSWORD_CHANGE: "Cambio password?",
        PASSWORD: "Password",
        PASSWORD_HINT: "Almeno 8 caratteri",
        EMAIL_ERROR: "L'email deve essere nel formato corretto",
        EMAIL_REQUIRED: "L'email è richiesta",
        MIN_LENGHT_USERNAME: "L'Username deve essere almeno di 3 caratteri"
      },
      DIALOG: {
        ACTUAL_CREDITS: "Crediti attuali",
        MANAGE: {
          CODE: "Codice azienda",
          TITLE: "Modifica utente",
          ID: "Id",
          OWNER_ID: "Referente",
          USERNAME: "Username",
          EMAIL: "Email",
          MEMBER_GROUP: "Membro del gruppo",
          CREATED_AT: "Data creazione",
          UPDATED_AT: "Ultima modifica",
          LAST_LOGIN: "Ultimo accesso",
          NOTE: "Note",
          PASSWORD_CHANGE: "Cambio password?",
          PASSWORD: "Password",
          RE_PASSWORD: "Conferma password",
          STATUS: "Stato",
          ACTIVE: "Attivo",
          NOT_ACTIVE: "Bloccato",
          EMAIL_ERROR: "L'email deve essere nel formato corretto",
          EMAIL_REQUIRED: "L'email è richiesta",
          ERROR_TOTAL_ALLOW_TRIAL:
            "Il totale deve essere compreso tra 0 e 99999"
        }
      }
    },
    DOSES: {
      AREA: "Dosi vaccino",
      DASHBOARD: {
        TITLE: "Bacheca",
        SUBTITLE: "Dosi"
      },
      LIST: {
        TITLE: "Lista",
        TABLE: {
          TITLE: "Lista Dosi",
          FILTER: "Filtra"
        },
        FILTERS: {
          DENOMINATION: "Denominazione"
        }
      },
      NEW: {
        TITLE: "Nuova tipologia dose",
        FORM: {}
      },
      EDIT: {
        TITLE: "Modifica tipologia dose",
        FORM: {}
      },
      FORM: {
        MANAGE: {
          ERROR_PHONE: "Formato telefono errato (solo numeri)"
        }
      },
      ATTRIBUTES: {
        ID: "Id",
        TITLE: "Titolo",
        DESCRIPTION: "Descrizione",
        WEIGHT: "Peso nell'ordinamento "
      },
      COMMON: {
        ERROR_ONSAVE: "Errore durante il salvataggio della tipologia di dose"
      }
    },
    EVENTS: {
      AREA: "Eventi",
      DASHBOARD: {
        TITLE: "Bacheca",
        SUBTITLE: "Eventi"
      },
      LIST: {
        TITLE: "Lista",
        TABLE: {
          TITLE: "Lista Eventi",
          FILTER: "Filtra"
        },
        FILTERS: {
          DENOMINATION: "Denominazione"
        },
        DOWNLOAD_XLS_BUTTON: "Scarica file Excel"
      },
      NEW: {
        TITLE: "Nuova evento",
        FORM: {}
      },
      EDIT: {
        TITLE: "Modifica",
        FORM: {},
        DOSES_COUNT: "Tipologie di dosi selezionate",
        ALL_SELECTED: "Tutte le tipologie di dosi selezionate"
      },
      FORM: {
        MANAGE: {
          ERROR_PHONE: "Formato telefono errato (solo numeri)"
        }
      },
      ATTRIBUTES: {
        ID: "Id",
        CODE: "Codice",
        TITLE: "Titolo",
        START_DATE: "Inizio",
        START_HOUR: "Inizio alle ore",
        END_DATE: "Fine",
        END_HOUR: "Fino alle ore",
        TIME_SLOT: "Divisione ora in slot",
        RESERVATIONS_PER_TIME_SLOT: "Prenotazioni per ogni slot orario",
        CITY: "Città",
        OWNER: "Organizzatore",
        DESCRIPTION: "Descrizione",
        ADDRESS: "Indirizzo",
        NOTES: "Note",
        DOSES: "DOSI"
      },
      COMMON: {
        ERROR_ONSAVE: "Errore durante il salvataggio della tipologia di dose"
      }
    },
    CONSTRUCTION_SITES: {
      AREA: "Cantieri",
      COMMON: {
        CODE: "Codice",
        STATUS_1: "STATO 1",
        STATUS_2: "STATO 2",
        STATUS_3: "STATO 3",
        VAT: "P.IVA",
        FISCAL_CODE: "Codice fiscale",
        ADDRESS: "Indirizzo",
        COMPANY_INFO: "Vedi azienda",
        STATE_STATE: "Provincia cantiere",
        STATE_PLACE_OF_JURISDICTION: "Provincia competenza",
        STATE_DISTRICT: "Distretto cantiere"
      },
      WIDGET_RESUME: {
        TITLE: "Ultimi cantieri",
        NORESULTS: "Nessun cantiere trovato"
      },
      WIDGET_NOTIFICATIONS: {
        TITLE: "Ultime notifiche"
      },
      WIDGET_LAST_WORKERS: {
        TITLE: "Ultimi impiegati"
      },
      NEW: {
        TITLE: "Nuovo"
      },
      LIST: {
        TITLE: "Lista",
        TABLE: {
          TITLE: "Lista cantieri",
          FILTER: "Filtra"
        },
        FILTERS: {
          DENOMINATION: "Denominazione",
          COMPANY: "Azienda",
          DESCRIPTION: "Descrizione",
          SUBSCRIBED_FROM: "Iscritto dal",
          SUBSCRIBED_TO: "Iscritto al",
          LOCATION: "Località",
          STATES: "Provincia",
          CODE: "Codice",
          VAT: "P.IVA",
          FISCAL_CODE: "Cod.Fiscale",
          STATUS: "Stato",
          STATUS_1: "Attiva",
          STATUS_2: "Sospesa",
          STATUS_3: "Cancellata",
          ADDRESS: "Indirizzo",
          FILTER: "Filtra"
        }
      },
      OVERVIEW: {
        TITLE: "Riepilogo"
      }
    },
    COMPANIES: {
      AREA: "Aziende",
      COMMON: {
        NEW_USER: "Nuova azienda",
        ERROR_ONSAVE: "Errore!"
      },
      DASHBOARD: {
        TITLE: "Bacheca",
        SUBTITLE: "aziende"
      },
      LIST: {
        TITLE: "Lista",
        TABLE: {
          TITLE: "Lista Aziende",
          FILTER: "Filtra"
        },
        FILTERS: {
          DENOMINATION: "Denominazione",
          COMPANY: "Azienda",
          DESCRIPTION: "Descrizione",
          SUBSCRIBED_FROM: "Iscritto dal",
          SUBSCRIBED_TO: "Iscritto al",
          LOCATION: "Località",
          STATES: "Provincia",
          CODE: "Codice",
          VAT: "P.IVA",
          FISCAL_CODE: "Cod.Fiscale",
          STATUS_1: "Attiva",
          STATUS_2: "Sospesa",
          STATUS_3: "Cancellata",
          FILTER: "Filtra"
        }
      },
      NEW: {
        TITLE: "Nuova azienda",
        FORM: {}
      },
      OVERVIEW: {
        TITLE: "Riepilogo",
        BUTTON_EDIT: "Modifica",
        BUTTON_CONSTRUCTION_SITES_LIST: "Cantieri",
        EMAIL: "Email",
        PHONE: "Telefono",
        MOBILE_PHONE: "Telefono Mobile",
        FAX: "Fax",
        SUB_MENU: {
          OVERVIEW: "Riepilogo",
          VIEW: "Scheda",
          EDIT_DATA: "Modifica dati",
          CONSTRUCTIONS_SITE: "Cantieri",
          POSITION_HISTORY: "Storico posizione",
          LOG: "Storico modifiche"
        },
        COMPANY_INFORMATION: {
          TITLE: "Dati Azienda"
        },
        EDIT: {
          TITLE_ENABLED: "Sblocca dati",
          TITLE_DISABLED: "Blocca dati"
        }
      },
      FORM: {
        MANAGE: {
          ID: "Id",
          CODE: "Codice azienda",
          CONSULTANT_ID: "Referente",
          CREATED_AT: "Aggiunto",
          UPDATED_AT: "Ultimo aggiornamento",
          STATUS: "Stato",
          NOT_ACTIVE: "Sospesa",
          ACTIVE: "Attiva",
          DELETED: "Cancellata",
          SUBSCRIPTION_DATE: "Data Iscrizione",
          SUSPENSION_DATE: "Data Sospesione",
          DELETION_DATE: "Data Cancellazione",
          DENOMINATION: "Denominazione",
          VAT: "P.IVA",
          FISCAL_CODE: "Codice Fiscale",
          COUNTRIES: "Paese",
          REGIONS: "Regione",
          STATES: "Provincia",
          CITIES: "Città",
          ADDRESS: "Indirizzo",
          CAP: "Cap",
          SUB_TITLE_BOX_1: "Informazioni azienda",
          POSITION: "Posizione",
          INPS: "Posizione INPS",
          INAIL: "Posizione INAIL",
          FISCAL_STATE: "Provincia Fiscale",
          MEMBERSHIP_SUBSCRIPTION: "Lettera adesione",
          WORK_CONTRACT: "Contratto di lavoro APC",
          ASSOCIATION_TYPE: "Associazione",
          AUTHORITY: "Ente previdenziale",
          ERROR_VAT: "P.IVA errata",
          YES: "Si",
          NOT: "No ",
          SAVING_LOADING: "Salvataggio",
          ERROR_VAT_USED: "P.IVA già utilizzata"
        }
      },
      ATTRIBUTES: {
        USERNAME: "Username",
        EMAIL: "Email",
        NOTE: "Note",
        MEMBER_GROUP: "Membro del gruppo",
        USERNAME_REQUIRED: "Username è richiesto",
        OWNER_ID: "Referente",
        PASSWORD_CHANGE: "Cambio password?",
        PASSWORD: "Password",
        PASSWORD_HINT: "Almeno 8 caratteri",
        EMAIL_ERROR: "L'email deve essere nel formato corretto",
        EMAIL_REQUIRED: "L'email è richiesta",
        MIN_LENGHT_USERNAME: "L'Username deve essere almeno di 3 caratteri",
        MIN_LENGHT_DENOMINATION:
          "Denominazione deve essere almeno di 2 caratteri",
        DENOMINATION_REQUIRED: "Denominazione è richiesto"
      },
      DIALOG: {
        ACTUAL_CREDITS: "Crediti attuali",
        MANAGE: {
          TITLE: "Modifica azienda",
          ID: "Id",
          CONSULTANT_ID: "Consulente",
          DENOMINATION: "Denominazione",
          VAT: "P.IVA",
          FISCAL_CODE: "Codice fiscale",
          EMAIL: "Email",
          SUSPENSION_DATE: "Data sospensione",
          SUBSCRIPTION_DATE: "Data iscrizione",
          DELETION_DATE: "Data cancellazione",
          POSITION: "Posizione",
          INAIL: "INAIL",
          INPS: "INPS",
          MEMBER_GROUP: "Membro del gruppo",
          CREATED_AT: "Data creazione",
          UPDATED_AT: "Ultima modifica",
          LAST_LOGIN: "Ultimo accesso",
          NOTE: "Note",
          PASSWORD_CHANGE: "Cambio password?",
          PASSWORD: "Password",
          RE_PASSWORD: "Conferma password",
          STATUS: "Stato",
          ACTIVE: "Attivo",
          NOT_ACTIVE: "Bloccato",
          EMAIL_ERROR: "L'email deve essere nel formato corretto",
          EMAIL_REQUIRED: "L'email è richiesta",
          ERROR_VAT: "Formato o partita iva errata"
        }
      }
    },
    MEMBER_GROUPS: {
      AREA: "Gruppi utenti",
      NEW: {
        TITLE: "Nuovo gruppo"
      },
      LIST: {
        TITLE: "Lista",
        AREA: "Lista",
        TABLE: {
          FILTER: "Filtro",
          TITLE: "Lista gruppi utenti"
        }
      },
      ATTRIBUTES: {
        TITLE: "Nome gruppo",
        ID: "Id",
        GROUP_NAME: "Titolo",
        ADMIN: "Admin",
        BANNED: "Bannato",
        ERROR_TOTAL_ALLOW_TRIAL: "Il totale deve essere compreso tra 0 e 999",
        DELETE_USERS: "Eliminare utenti",
        CHANGE_PASSWORD: "Cambiare password",
        ALLOW_IMPORT: "Importazione",
        ALLOW_EXPORT: "Esportazione",
        NOTE: "Note",
        MIN_LENGHT_TITLE: "Numero minimo di caratteri: 5",
        CREATED_AT: "Creato il",
        UPDATED_AT: "Aggiornato il"
      },
      DIALOG: {
        MANAGE: {
          TITLE: "Modifica gruppo utenti",
          ID: "Id",
          GROUP_NAME: "Titolo",
          ADMIN: "Admin",
          ERROR_TOTAL_ALLOW_TRIAL: "Il totale deve essere compreso tra 0 e 999",
          CAN_DELETE: "Eliminare utenti",
          ALLOW_IMPORT: "Importazione",
          ALLOW_EXPORT: "Esportazione"
        }
      }
    },
    DASHBOARD: {
      TITLE: "Dashboard Prenotazioni Vaccino",
      WIDGET: {
        RESERVATIONS: {
          TITLE: "Ultime prenotazioni ricevute",
          SUB_TITLE: "",
          TABLE: {
            EVENT_DATE: "Data evento",
            RESERVATION_DATE: "Data prenotazione",
            USER_TITLE: "Richiedente",
            EVENT_TITLE: "Evento",
            DOSE: "Dose scelta",
            STATUS: "Stato"
          }
        }
      }
    }
  }
};
