import ApiService from "@/core/services/api.service";

// action types
export const FETCH_RESERVATIONS_LIST = "fetchReservationsList";
export const FETCH_RESERVATIONS_FULL_LIST = "fetchReservationsFullList";
export const FETCH_RESERVATIONS_CHECK = "fetchReservationsListCheck";
export const GET_RESERVATION_BY_ID = "getReservationById";
export const UPDATE_RESERVATIONS_DATA = "updateReservationsData";
export const CREATE_RESERVATIONS_DATA = "createReservationsData";
export const DELETE_RESERVATION = "deleteReservation";
export const SET_RESERVATIONS_ERROR = "setReservationError";
export const RESET_RESERVATIONS_IN_CHECK = "resetReservationInCheck";

// mutation types
export const SET_RESERVATIONS_LIST = "setReservationsList";
export const SET_RESERVATIONS_FULL_LIST = "setReservationsFullList";
export const SET_RESERVATIONS_IN_USE = "setReservationsInUse";
export const SET_RESERVATIONS_LOADING = "setReservationsLoading";
export const SET_RESERVATIONS_FULL_LOADING = "setReservationsFullLoading";
export const SET_RESERVATIONS_SAVE_LOADING = "setSaveReservationLoading";
export const SET_RESERVATIONS_IN_CHECK = "setReservationsInCheck";
export const SET_RESERVATIONS_SAVING_SUCCESS = "setReservationSavingSuccess";

const state = {
  reservations_list: {},
  reservations_full_list: {},
  reservation_in_use: {},
  reservation_in_check: {},
  errors: null,
  calling: {},
  loadingListFullReservations: false,
  loadingListReservations: false,
  loadingSaveReservation: false,
  savingSuccess: false
};

const getters = {
  reservationsList(state) {
    return state.reservations_list;
  },
  reservationsFullList(state) {
    return state.reservations_full_list;
  },
  reservationInUse(state) {
    return state.reservation_in_use.data;
  },
  reservationInCheck(state) {
    return state.reservation_in_check.data;
  },
  reservationLoading(state) {
    return state.loadingListReservations;
  },
  reservationFullLoading(state) {
    return state.loadingListFullReservations;
  },
  reservationSaveLoading(state) {
    return state.loadingSaveReservation;
  },
  getReservationApiErrors(state) {
    return state.errors;
  },
  getReservationSavingSuccess(state) {
    return state.savingSuccess;
  }
};

const actions = {
  [GET_RESERVATION_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/reservations/" + id)
        .then(({ data }) => {
          context.commit(SET_RESERVATIONS_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_RESERVATIONS_LIST](context, filters) {
    context.commit(SET_RESERVATIONS_LOADING, true);
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/reservations/search", filters)
        .then(({ data }) => {
          context.commit(SET_RESERVATIONS_LIST, data);
          resolve(data);
          context.commit(SET_RESERVATIONS_LOADING, false);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_RESERVATIONS_FULL_LIST](context, filters) {
    context.commit(SET_RESERVATIONS_FULL_LOADING, true);
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/reservations/search", filters)
        .then(({ data }) => {
          context.commit(SET_RESERVATIONS_FULL_LIST, data);
          resolve(data);
          context.commit(SET_RESERVATIONS_FULL_LOADING, false);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_RESERVATIONS_CHECK](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/reservations/search", filters)
        .then(({ data }) => {
          context.commit(SET_RESERVATIONS_IN_CHECK, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_RESERVATIONS_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_RESERVATIONS_SAVE_LOADING, true);
    context.commit(SET_RESERVATIONS_SAVING_SUCCESS, false);
    ApiService.put("/api/admin/v1/reservations/" + data.id, data)
        .then(() => {
          context.commit(SET_RESERVATIONS_SAVE_LOADING, false);
          context.commit(SET_RESERVATIONS_SAVING_SUCCESS, true);
        })
        .catch((err) => {
          context.commit(SET_RESERVATIONS_SAVE_LOADING, false);
          context.commit(SET_RESERVATIONS_ERROR, err);
        });
  },
  [RESET_RESERVATIONS_IN_CHECK](context) {
    context.commit(SET_RESERVATIONS_IN_CHECK, {});
  },
  [CREATE_RESERVATIONS_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_RESERVATIONS_SAVE_LOADING, true);
    context.commit(SET_RESERVATIONS_SAVING_SUCCESS, false);
    ApiService.post("/api/admin/v1/reservations/", data)
      .then(() => {
        context.commit(SET_RESERVATIONS_SAVE_LOADING, false);
        context.commit(SET_RESERVATIONS_SAVING_SUCCESS, true);
      })
      .catch((err) => {
        context.commit(SET_RESERVATIONS_SAVE_LOADING, false);
        context.commit(SET_RESERVATIONS_ERROR, err);
      });
  },
  [DELETE_RESERVATION](context, data) {
    ApiService.setHeader();
    ApiService.delete("/api/admin/v1/reservations/" + data, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_RESERVATIONS_ERROR, err);
      });
  }
};

const mutations = {
  [SET_RESERVATIONS_LIST](state, reservations_list) {
    state.reservations_list = reservations_list;
  },
   [SET_RESERVATIONS_FULL_LIST](state, reservations_list) {
    state.reservations_full_list = reservations_list;
  },
  [SET_RESERVATIONS_IN_USE](state, reservation_in_use) {
    state.reservation_in_use = reservation_in_use;
  },
  [SET_RESERVATIONS_IN_CHECK](state, reservation_in_check) {
    state.reservation_in_check = reservation_in_check;
  },
  [SET_RESERVATIONS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_RESERVATIONS_LOADING](state, value) {
    state.loadingListReservations = value;
  },
  [SET_RESERVATIONS_FULL_LOADING](state, value) {
    state.loadingListFullReservations = value;
  },
  [SET_RESERVATIONS_SAVE_LOADING](state, value) {
    state.loadingSaveReservation = value;
  },
  [SET_RESERVATIONS_SAVING_SUCCESS](state, value) {
    state.savingSuccess = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
