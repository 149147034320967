import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue")
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue")
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue")
            }
          ]
        },
        {
          path: "/citizens",
          area: "citizens",
          redirect: "/citizens/dashboard",
          component: () => import("@/view/pages/citizens/Citizens"),
          children: [
            {
              path: "/citizens/overview/:id",
              name: "citizens_overview",
              component: () => import("@/view/pages/citizens/Overview")
            },
            {
              path: "/citizens/dashboard",
              name: "citizens_dashboard",
              menu: "citizens",
              component: () => import("@/view/pages/citizens/Dashboard")
            },
            {
              path: "list",
              name: "citizens_list",
              menu: "citizens",
              component: () => import("@/view/pages/citizens/List")
            },
            {
              path: "reservations/:id",
              name: "citizens_reservations_list",
              menu: "citizens",
              component: () => import("@/view/pages/citizens/ReservationsList")
            },
            {
              path: "new",
              name: "citizens_new",
              menu: "citizens",
              component: () => import("@/view/pages/citizens/New")
            },
            {
              path: "edit/:id",
              name: "citizens_form",
              component: () => import("@/view/pages/citizens/Edit")
            }
          ]
        },
        {
          path: "/reservations",
          area: "reservations",
          redirect: "/reservations/list",
          component: () => import("@/view/pages/reservations/Reservations"),
          children: [
            {
              path: "/reservations/overview/:id",
              name: "reservations_overview",
              component: () => import("@/view/pages/reservations/Overview")
            },
            {
              path: "/reservations/dashboard",
              name: "reservations_dashboard",
              menu: "reservations",
              component: () => import("@/view/pages/reservations/Dashboard")
            },
            {
              path: "list",
              name: "reservations_list",
              menu: "reservations",
              component: () => import("@/view/pages/reservations/List")
            },
            {
              path: "new",
              name: "reservations_new",
              menu: "reservations",
              component: () => import("@/view/pages/reservations/New")
            },
            {
              path: "edit/:id",
              name: "reservations_form",
              component: () => import("@/view/pages/reservations/Edit")
            }
          ]
        },
        {
          path: "/doses",
          area: "doses",
          redirect: "/doses/list",
          component: () => import("@/view/pages/doses/Doses"),
          children: [
            {
              path: "list",
              name: "doses_list",
              menu: "doses",
              component: () => import("@/view/pages/doses/List")
            },
            {
              path: "new",
              name: "doses_new",
              menu: "doses",
              component: () => import("@/view/pages/doses/New")
            },
            {
              path: "edit/:id",
              name: "doses_form",
              component: () => import("@/view/pages/doses/Edit")
            }
          ]
        },
        {
          path: "/events",
          area: "events",
          redirect: "/events/list",
          component: () => import("@/view/pages/events/Events"),
          children: [
            {
              path: "list",
              name: "events_list",
              menu: "events",
              component: () => import("@/view/pages/events/List")
            },
            {
              path: "new",
              name: "events_new",
              menu: "events",
              component: () => import("@/view/pages/events/New")
            },
            {
              path: "edit/:id",
              name: "events_form",
              component: () => import("@/view/pages/events/Edit")
            },
            {
              path: "reservations/:id",
              name: "reservations_list_per_events",
              component: () => import("@/view/pages/events/ListReservation")
            }
          ]
        },
        {
          path: "/users",
          redirect: "/users/dashboard",
          component: () => import("@/view/pages/users/Users"),
          children: [
            {
              path: "/users/dashboard",
              name: "users_dashboard",
              component: () => import("@/view/pages/users/Dashboard")
            },
            {
              path: "list",
              name: "users_list",
              component: () => import("@/view/pages/users/List")
            },
            {
              path: "new_user",
              name: "users_new",
              component: () => import("@/view/pages/users/New")
            },
            {
              path: "edit_user/:id",
              name: "users_edit",
              component: () => import("@/view/pages/users/Edit")
            },
            {
              path: "member_groups_list",
              name: "member_groups_list",
              component: () => import("@/view/pages/users/MemberGroupsList")
            },
            {
              path: "add_member_groups",
              name: "add_member_groups",
              component: () => import("@/view/pages/users/NewMemberGroup")
            }
          ]
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
