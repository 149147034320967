import ApiService from "@/core/services/api.service";

// action types
export const FETCH_DOSES_LIST = "fetchDosesList";
export const FETCH_DOSES_CHECK = "fetchDosesListCheck";
export const GET_DOSE_BY_ID = "getDoseById";
export const UPDATE_DOSE_DATA = "updateDosesData";
export const CREATE_DOSE_DATA = "createDosesData";
export const DELETE_DOSE = "deleteDoses";
export const SET_ERROR = "setError";

// mutation types
export const SET_DOSES_LIST = "setDosesList";
export const SET_DOSE_IN_USE = "setDosesInUse";
export const SET_DOSES_LOADING = "setDosesLoading";
export const SET_DOSE_SAVE_LOADING = "setSaveDoseLoading";
export const SET_DOSES_IN_CHECK = "setDosesInCheck";
export const SET_DOSE_SAVING_SUCCESS = "setDoseSavingSuccess";

const state = {
  doses_list: {},
  dose_in_use: {},
  dose_in_check: {},
  errors: null,
  calling: {},
  loadingListDoses: false,
  loadingSaveDose: false,
  savingSuccess: false
};

const getters = {
  dosesList(state) {
    return state.doses_list;
  },
  doseInUse(state) {
    return state.dose_in_use;
  },
  doseInCheck(state) {
    return state.dose_in_check.data;
  },
  doseLoading(state) {
    return state.loadingListDoses;
  },
  doseSaveLoading(state) {
    return state.loadingSaveDose;
  },
  getDoseApiErrors(state) {
    return state.errors;
  },
  getDoseSavingSuccess(state) {
    return state.savingSuccess;
  }
};

const actions = {
  [GET_DOSE_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/doses/" + id)
        .then(({ data }) => {
          context.commit(SET_DOSE_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_DOSES_LIST](context, filters) {
    context.commit(SET_DOSES_LOADING, true);
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/doses/search", filters)
        .then(({ data }) => {
          context.commit(SET_DOSES_LIST, data);
          resolve(data);
          context.commit(SET_DOSES_LOADING, false);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_DOSES_CHECK](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/doses/search", filters)
        .then(({ data }) => {
          context.commit(SET_DOSES_IN_CHECK, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_DOSE_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_DOSE_SAVE_LOADING, true);
    ApiService.put("/api/admin/v1/doses/" + data.id, data)
      .then(() => {
        context.commit(SET_DOSE_SAVING_SUCCESS, true);
        context.commit(SET_DOSE_SAVE_LOADING, false);
      })
      .catch((err) => {
        context.commit(SET_DOSE_SAVE_LOADING, false);
        context.commit(SET_ERROR, err);
      });
  },
  [CREATE_DOSE_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_DOSE_SAVE_LOADING, true);
    ApiService.post("/api/admin/v1/doses/", data)
      .then(() => {
        context.commit(SET_DOSE_SAVING_SUCCESS, true);
        context.commit(SET_DOSE_SAVE_LOADING, false);
      })
      .catch((err) => {
        context.commit(SET_DOSE_SAVE_LOADING, false);
        context.commit(SET_ERROR, err);
      });
  },
  [DELETE_DOSE](context, data) {
    ApiService.setHeader();
    ApiService.delete("/api/admin/v1/doses/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  }
};

const mutations = {
  [SET_DOSES_LIST](state, doses_list) {
    state.doses_list = doses_list;
  },
  [SET_DOSE_IN_USE](state, dose_in_use) {
    state.dose_in_use = dose_in_use;
  },
  [SET_DOSES_IN_CHECK](state, dose_in_check) {
    state.dose_in_check = dose_in_check;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_DOSES_LOADING](state, value) {
    state.loadingListDoses = value;
  },
  [SET_DOSE_SAVE_LOADING](state, value) {
    state.loadingSaveDose = value;
  },
  [SET_DOSE_SAVING_SUCCESS](state, value) {
    state.savingSuccess = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
