import ApiService from "@/core/services/api.service";

// action types
export const FETCH_REG_USERS_LIST = "fetchRegUsersList";
export const GET_REG_USER_BY_ID = "getRegUser";

// mutation types
export const SET_SINGLE_REG_USER = "setSingleRegUser";
export const SET_REG_USERS_LIST = "setRegUsersList";
export const SET_REG_USERS_SAVE_LOADING = "setRegUsersSaveLoading";
export const SET_ERROR_REG_USERS = "setErrorRegUsers";
export const UPDATE_REG_USER_DATA = "updateRegUserData";
export const CREATE_REG_USER_DATA = "createRegUserData";
export const DELETE_REG_USER = "deleteRegUser";

const state = {
  reg_users_list: {},
  single_reg_user: {},
  regUsersSaveLoading: false,
  errors: null,
};

const getters = {
  singleRegUser(state) {
    return state.single_reg_user;
  },
  regUsersList(state) {
    return state.reg_users_list;
  },
  regUsersSaveLoading(state) {
    return state.regUsersSaveLoading;
  },
  getRegUsersApiErrors(state) {
    return state.errors;
  },
};

const actions = {
  [GET_REG_USER_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/reg_users/" + id)
        .then(({ data }) => {
          context.commit(SET_SINGLE_REG_USER, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [FETCH_REG_USERS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/reg_users/search", filters)
        .then(({ data }) => {
          context.commit(SET_REG_USERS_LIST, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [UPDATE_REG_USER_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_ERROR_REG_USERS, false);
    context.commit(SET_REG_USERS_SAVE_LOADING, true);
    ApiService.put("/api/admin/v1/reg_users/" + data.id, data)
      .then(() => {
        context.commit(SET_ERROR_REG_USERS, false);
        context.commit(SET_REG_USERS_SAVE_LOADING, false);
      })
      .catch((err) => {
        context.commit(SET_REG_USERS_SAVE_LOADING, false);
        context.commit(SET_ERROR_REG_USERS, err);
      });
  },
  [CREATE_REG_USER_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_REG_USERS_SAVE_LOADING, true);
    ApiService.post("/api/admin/v1/reg_users/", data)
      .then(() => {
        context.commit(SET_ERROR_REG_USERS, false);
      })
      .catch((err) => {
        context.commit(SET_REG_USERS_SAVE_LOADING, false);
        context.commit(SET_ERROR_REG_USERS, err);
      });
  },
  [DELETE_REG_USER](context, data) {
    ApiService.setHeader();
    ApiService.delete("/api/admin/v1/reg_users/" + data.id, data)
      .then(() => {
      })
      .catch(() => {});
  },
};

const mutations = {
  [SET_SINGLE_REG_USER](state, user) {
    state.single_reg_user = user;
  },
  [SET_REG_USERS_LIST](state, users_list) {
    state.reg_users_list = users_list;
  },
  [SET_REG_USERS_SAVE_LOADING](state, value) {
    state.regUsersSaveLoading = value;
  },
  [SET_ERROR_REG_USERS](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
