import ApiService from "@/core/services/api.service";

// action types
export const FETCH_COUNTRIES_LIST = "fetchCountriesList";
export const GET_CITY_BY_ID = "getCountryById";
export const UPDATE_CITY_DATA = "updateCountriesData";
export const CREATE_CITY_DATA = "createCountriesData";
export const DELETE_CITY = "deleteCountries";
export const SET_ERROR = "setError";

// mutation types
export const SET_COUNTRIES_LIST = "setCountriesList";
export const SET_CITY_IN_USE = "setCountryInUse";

const state = {
  countries_list: {},
  country_in_use: {},
  errors: null,
  calling: {},
};

const getters = {
  countriesList(state) {
    return state.countries_list;
  },
  countryInUse(state) {
    return state.country_in_use.data;
  },
};

const actions = {
  [GET_CITY_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/countries/" + id)
        .then(({ data }) => {
          context.commit(SET_CITY_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_COUNTRIES_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/countries/search", filters)
        .then(({ data }) => {
          context.commit(SET_COUNTRIES_LIST, data.data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [UPDATE_CITY_DATA](context, data) {
    ApiService.setHeader();
    ApiService.put("/api/admin/v1/countries/" + data.id, data)
      .then(() => {
      })
      .catch(() => {});
  },
  [CREATE_CITY_DATA](context, data) {
    ApiService.setHeader();
    ApiService.post("/api/admin/v1/countries/", data)
      .then(() => {
        // console.log('true')
        // return true;
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
  [DELETE_CITY](context, data) {
    ApiService.setHeader();
    ApiService.delete("/api/admin/v1/countries/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  },
};

const mutations = {
  [SET_COUNTRIES_LIST](state, countries_list) {
    state.countries_list = countries_list;
  },
  [SET_CITY_IN_USE](state, countries_in_use) {
    state.country_in_use = countries_in_use;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
