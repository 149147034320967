import ApiService from "@/core/services/api.service";

// action types
export const FETCH_EVENTS_LIST = "fetchEventsList";
export const FETCH_EVENTS_CHECK = "fetchEventsListCheck";
export const GET_EVENT_BY_ID = "getEventById";
export const UPDATE_EVENT_DATA = "updateEventsData";
export const CREATE_EVENT_DATA = "createEventsData";
export const DELETE_EVENT = "deleteEvents";
export const SET_ERROR = "setError";

// mutation types
export const SET_EVENTS_LIST = "setEventsList";
export const SET_EVENT_IN_USE = "setEventsInUse";
export const SET_EVENTS_LOADING = "setEventsLoading";
export const SET_EVENT_SAVE_LOADING = "setSaveEventLoading";
export const SET_EVENTS_IN_CHECK = "setEventsInCheck";
export const SET_EVENT_SAVING_SUCCESS = "setEventSavingSuccess";

const state = {
  events_list: {},
  event_in_use: {},
  event_in_check: {},
  errors: null,
  calling: {},
  loadingListEvents: false,
  loadingSaveEvent: false,
  savingSuccess: false
};

const getters = {
  eventsList(state) {
    return state.events_list;
  },
  eventInUse(state) {
    return state.event_in_use;
  },
  eventInCheck(state) {
    return state.event_in_check.data;
  },
  eventLoading(state) {
    return state.loadingListEvents;
  },
  eventSaveLoading(state) {
    return state.loadingSaveEvent;
  },
  getEventApiErrors(state) {
    return state.errors;
  },
  getEventSavingSuccess(state) {
    return state.savingSuccess;
  }
};

const actions = {
  [GET_EVENT_BY_ID](context, id) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("/api/admin/v1/events/" + id)
        .then(({ data }) => {
          context.commit(SET_EVENT_IN_USE, data);
          resolve(data);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_EVENTS_LIST](context, filters) {
    context.commit(SET_EVENTS_LOADING, true);
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/events/search", filters)
        .then(({ data }) => {
          context.commit(SET_EVENTS_LIST, data);
          resolve(data);
          context.commit(SET_EVENTS_LOADING, false);
        })
        .catch(() => {});
    });
    // context.commit(SET_USERS_LIST, payload);
  },
  [FETCH_EVENTS_CHECK](context, filters) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.post("/api/admin/v1/events/search", filters)
        .then(({ data }) => {
          context.commit(SET_EVENTS_IN_CHECK, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_EVENT_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_EVENT_SAVE_LOADING, true);
    ApiService.put("/api/admin/v1/events/" + data.id, data)
      .then(() => {
        context.commit(SET_EVENT_SAVING_SUCCESS, true);
        context.commit(SET_EVENT_SAVE_LOADING, false);
      })
      .catch((err) => {
        context.commit(SET_EVENT_SAVE_LOADING, false);
        context.commit(SET_ERROR, err);
      });
  },
  [CREATE_EVENT_DATA](context, data) {
    ApiService.setHeader();
    context.commit(SET_EVENT_SAVE_LOADING, true);
    ApiService.post("/api/admin/v1/events/", data)
      .then(() => {
        context.commit(SET_EVENT_SAVING_SUCCESS, true);
        context.commit(SET_EVENT_SAVE_LOADING, false);
      })
      .catch((err) => {
        context.commit(SET_EVENT_SAVE_LOADING, false);
        context.commit(SET_ERROR, err);
      });
  },
  [DELETE_EVENT](context, data) {
    ApiService.setHeader();
    ApiService.delete("/api/admin/v1/events/" + data.id, data)
      .then(() => {
      })
      .catch((err) => {
        context.commit(SET_ERROR, err);
      });
  }
};

const mutations = {
  [SET_EVENTS_LIST](state, events_list) {
    state.events_list = events_list;
  },
  [SET_EVENT_IN_USE](state, event_in_use) {
    state.event_in_use = event_in_use;
  },
  [SET_EVENTS_IN_CHECK](state, event_in_check) {
    state.event_in_check = event_in_check;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_EVENTS_LOADING](state, value) {
    state.loadingListEvents = value;
  },
  [SET_EVENT_SAVE_LOADING](state, value) {
    state.loadingSaveEvent = value;
  },
  [SET_EVENT_SAVING_SUCCESS](state, value) {
    state.savingSuccess = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
